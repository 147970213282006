
<template>
  <div class="container-menu">
    <item route="/home" name="Home" icon="Home" />
    <item
      route="/#"
      :name="$t('menu.mudar_tema')"
      icon="Tema"
      v-if="currentThemeFixed == 'off' || !currentThemeFixed"
    />

    <item
      v-if="existConfigCert"
      route="/certificates"
      :name="$t('menu.certificado')"
      icon="Certificado"
    />
    <item
      v-if="permission && commentReview"
      route="/comunidade"
      name="Comunidade"
      icon="Comunidade"
    />
    <item v-if="permission" route="/members" name="Membros" icon="Membro" />
    <item
      v-if="permission"
      route="/assinatura"
      name="Assinatura"
      icon="Assinatura"
    />
    <item v-if="moderationHelper" route="/ajuda" name="Ajuda" icon="Ajuda" />

    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/icons/sign-out.svg" />
        {{ $t('menu.logout.logout2') }}
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";
import CertifyService from "@/services/resources/CertifyService";
const serviceCertificate = CertifyService.build();
import Cookies from "js-cookie";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

export default {
  components: {
    Item,
  },
  data() {
    return {
      commentReview: true,
      moderationHelper: true,
      existConfigCert: true,
      permission: false,
    };
  },
  computed: {
    currentThemeFixed() {
      if (
        this.$store.getters.metasSite &&
        this.$store.getters.metasSite.theme_fixed_painel
      ) {
        return this.$store.getters.metasSite.theme_fixed_painel;
      } else return null;
    },
  },
  mounted() {
    this.getCertificateConfig();
    this.getPermission();
    this.getMetaHelper();
    this.getCommentReview();
    this.getCertificateConfig();
  },
  methods: {
    getMetaHelper() {
      serviceMeta
        .search("keys[]=helper_data")
        .then((resp) => {
          //console.log("meta helper", resp);
          var data = resp.helper_data;
          if (data === "off") {
            this.moderationHelper = false;
          } else {
            this.moderationHelper = true;
          }
          //console.log(this.moderationHelper);
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getCommentReview() {
      serviceMeta
        .search("keys[]=comment_review")
        .then((resp) => {
          //console.log("meta comments", resp);
          var data = resp.comment_review;
          if (data === "off") {
            this.commentReview = false;
          } else {
            this.commentReview = true;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getCertificateConfig() {
      serviceCertificate
        .read("check-exist")
        .then((resp) => {
          //console.log("check-exist", resp);
          this.existConfigCert = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getPermission() {
      let permission = Cookies.get("access_level");
      if (permission === "admin" || permission === "owner") {
        this.permission = true;
      } else {
        this.permission = false;
      }
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  /* height: 80vh; */
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: var(--backgroundcolor);
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: var(--fontcolor);
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>
